* {
  font-family: Salsa;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root{
  background-color: #CBBF74;
  color: #593612;
}

@font-face{
  font-family: Salsa;
  src: url('../public/fonts/Salsa-Regular.ttf');
}

.App{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.navbar-icon{
  color: #593612 !important;
}

.custom-navbar-btn{
  font-size: 25px !important;
  color: #593612 !important;
}

.banner-img{
  width: 110rem;
}

.custom-product-btn{
  font-size: 25px !important;
  width: 13rem;
  color: #593612 !important;
}

.op-selected{
  background-color: #593612 !important;
  color: #CBBF74 !important;
}

.search-component{
  gap: 1.5rem;
}

.custom-search-bar{
  width: 28rem;
  background-color: #BAA947;
  border: 0px;
}

.custom-search-bar::placeholder{
  color: #593612 !important;
  opacity: 0.4;
}

.custom-search-btn{
  width: 6rem;
  background-color: #593612 !important;
  color: #CBBF74 !important;
  opacity: 0.8;
}

.footer{
  margin-top: auto !important;
  background-color: #593612 !important;
  color: #CBBF74 !important;
}

.policies{
  font-size: 18px !important;
}

.social-media-title{
  font-size: 25px !important;
}

.social-media-group{
  gap: 1.5rem;
}

.social-media-option{
  font-size: 17px !important;
  text-decoration: none;
  color: #CBBF74;
}

.product-miniature{
  cursor: pointer;
}

.product-miniature-image{
  height: 225px;
  width: 225px;
  background-color: white;
}

.product-miniature-details{
  font-size: 20px;
  color: #593612 !important;
}

.catalog-items{
  gap: 1.5rem;
}

.product-miniature-price{
  opacity: 0.8;
}

.custom-footer-btn{
  color: #CBBF74 !important;
}

.form-title{
  font-size: 32px;
}

.textfield-small{
  border: 2.5px solid #593612;
  background-color: #CBBF74;
  height: 2.3rem;
}

.textarea{
  border: 2.5px solid #593612;
  background-color: #CBBF74;
  height: 11.5rem;
}

.required-fields{
  font-size: 12px;
}

.send-btn{
  font-size: 22px !important;
  width: 10.5rem;
  color: #CBBF74 !important;
  background-color: #593612 !important;
}

.product-image{
  background-color: white;
  height: 400px;
  width: 400px;
}

.product-image-secondary{
  background-color: white;
  height: 100px;
  width: 100px;
}

.product-price{
  font-size: 21px;
  opacity: 0.8;
}

.product-description{
  font-size: 18px;
}

.product-stock{
  font-size: 22px !important;
}

.btn-stock-options{
  font-size: 29px !important;
  color: #593612 !important;
}

.add-to-cart-btn{
  font-size: 18px !important;
  width: 10.5rem;
  color: #CBBF74 !important;
  background-color: #593612 !important;
}

.delete-product{
  font-size: 18px !important;
  width: 10.5rem;
  color: #593612 !important;
  background-color: #BAA947 !important;
}

.close-btn{
  color: #593612 !important;
  opacity: 1;
}

.product-image-basket{
  height: 90px;
  width: 90px;
  background-color: white;
}

.product-name-basket{
  font-size: 19px;
}

.product-price-basket{
  font-size: 19px;
  opacity: 0.8;
}

.btn-product-quantity{
  font-size: 24px !important;
  color: #593612 !important;
  border: 0 !important;
}

.product-quantity-basket{
  font-size: 22px !important;
}

.dotted-separator{
  border-bottom: dotted 5px #593612;
}

.dotted-separator-2{
  width: 11.5rem;
  height: 1rem;
  border-bottom: dotted 4px #593612;
}

.dotted-separator-3{
  border-bottom: dotted 2px #593612;
}

.dotted-separator-4{
  width: 85%;
  border-bottom: dotted 5px #CBBF74;
}

.separator-2{
  width: 80%;
  height: 1rem;
  border-bottom: solid 1px #593612;
}

.basket-total-price{
  font-size: 19px;
}

.basket-checkout-btn{
  font-size: 21px !important;
  width: 10.5rem;
  color: #CBBF74 !important;
  background-color: #593612 !important;
}

.modal-dialog-slideout {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  width: 450px !important;
  animation: slideInLeft 0.7s forwards !important;
  background-color: #BAA947 !important;
  height: 100vh !important;
  padding: 0%;
  margin: 0%;
  overflow: hidden !important;
  box-sizing: border-box;
  color: #593612;
}

.modal-dialog-slideout::-webkit-scrollbar {
  display: none;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(367.5%);
  }
  100% {
    transform: translateX(326.6%);
  }
}

.basket-modal-container{
  width: 450px !important;
  height: 100vh !important;
  background-color: #BAA947 !important;
  border: #BAA947 !important;
  overflow-y: auto;
  overflow-y: hidden;
  box-sizing: border-box;
  color: #593612;
}

.modal-content-register{
  background-color: #CBBF74 !important;
}

.textfield-small{
  border: 2.5px solid #593612;
  background-color: #CBBF74;
  color: #593612;
  height: 2.3rem;
}

.textfield-login{
  border: 2.5px solid #593612;
  color: #593612;
  background-color: #CBBF74 !important;
  height: 2.3rem;
}

.separator{
  border-bottom: solid 2px #593612;
}

.has-account{
  color: #593612;
  text-decoration: none;
  font-size: 12.5px;
  border-bottom: solid 1px #593612;
}

.new-account{
  color: #593612;
  font-size: 12.5px;
}

.login-btn{
  font-size: 19px !important;
  width: 11.5rem;
  color: #CBBF74 !important;
  background-color: #593612 !important;
}

.remember-me-text{
  font-size: 12.5px;
}

.remember-me{
  width: 18px;
  height: 18px;
  border-color: #593612 !important;
  background-color: #CBBF74 !important;
}

.modal-content-success{
  background-color: #CBBF74 !important;
}

.accept-btn{
  font-size: 17px !important;
  width: 7rem;
  color: #CBBF74 !important;
  background-color: #593612 !important;
}

.status{
  background-color: #C0A259;
  color: #593612;
  width: 9rem;
}

.product-image-order{
  width: 125px;
  height: 125px;
  background-color: white;
}

.order-btn{
  font-size: 19px !important;
  width: 9rem;
}

.order-details-btn{
  color: #593612 !important;
  background-color: #BAA947 !important;
}

.order-support-btn{
  color: #CBBF74 !important;
  background-color: #593612 !important;
}

.order-cancel-btn{
  color: #593612 !important;
}

.order-buttons{
  gap: 0.7rem;
}

.pagination{
  background-color: #BAA947;
}

.detail-title{
  font-size: 19px !important;
}

.print-btn{
  font-size: 19px !important;
  width: 9rem;
  background-color: #593612 !important;
  color: #CBBF74 !important;
}

.disabled{
  background-color: #BAA947;
}

.total-items{
  background-color: #BAA947 !important;
}

.status-select{
  background-color: #C0A259 !important;
}

.custom-modal{
  background-color: #CBBF74 !important;
  color: #593612 !important;
}

.user-menu-toggle{
  color: #593612 !important;
}

.dropdown-menu{
  background-color: #593612 !important;
  color: #CBBF74 !important;
  font-size: 19px !important;
}

.dropdown-menu a{
  color: #CBBF74 !important;
}

.dropdown-menu span{
  color: #CBBF74 !important;
}

.dropdown-menu button{
  color: #CBBF74 !important;
}

.custom-file-upload {
  width: 400px;
  height: 400px;
  cursor: pointer;
}

.custom-file-upload-secondary {
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.order-checkout-btn{
  font-size: 22px !important;
  width: 13rem;
  color: #CBBF74 !important;
  background-color: #593612 !important;
}

.about-me{
  height: 450px;
  cursor: pointer;
}

.dropdown-item{
  background-color: #593612 !important;
}

.order-details-modal{
  color: #593612 !important;
  background-color: #CBBF74 !important;
}

textarea{
  color: #593612 !important;
  outline: none;
}

input {
  outline: none;
}

input:-webkit-autofill {
  transition: all 5000s ease-in-out 0s;
}

textarea{
  transition: all 5000s ease-in-out 0s;
}

select{
  transition: all 5000s ease-in-out 0s;
}

.underline{
  text-decoration: none;
  color: #593612 !important;
  border-bottom: 1px solid #593612;
}

.chb-terms{
  height: 17px;
  width: 17px;
}